<template>
  <div >
    <SearchComponrntVue />
  </div>
</template>
<script>
import SearchComponrntVue from '@/components/SearchComponrnt.vue'
export default {
  components:{
    SearchComponrntVue
  },
  setup() {
    
  },
}
</script>
