<template>
  <div class="search-holder">
    <div class="container">
      <div class="text-center m-3" v-if="formState.alert">
        <v-alert
          :title="formState.alertTitle"
          :text="formState.alertMessage"
          closable
        ></v-alert>
      </div>
      <div class="search-div">
        <div class="text-center" style="margin-bottom:20px;"><img width="200" :src="Logo" /> </div>
        <h3 class="text-center main-title">
          The Advanced Company Entity Search
        </h3>
        <h6 style="text-align: center;margin-bottom: 30px;margin-top: -14px;font-size: 18px;opacity: 0.8;"> - We will tell you if almost any company is public or not - </h6>
        <div class="d-flex justify-center w-100">
          <div class="w-100 input-field">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 30.239 30.239"
            >
              <path
                d="M20.194 3.46c-4.613-4.613-12.121-4.613-16.734 0-4.612 4.614-4.612 12.121 0 16.735 4.108 4.107 10.506 4.547 15.116 1.34.097.459.319.897.676 1.254l6.718 6.718a2.498 2.498 0 003.535 0 2.496 2.496 0 000-3.535l-6.718-6.72a2.5 2.5 0 00-1.253-.674c3.209-4.611 2.769-11.008-1.34-15.118zm-2.121 14.614c-3.444 3.444-9.049 3.444-12.492 0-3.442-3.444-3.442-9.048 0-12.492 3.443-3.443 9.048-3.443 12.492 0 3.444 3.444 3.444 9.048 0 12.492z"
                fill="#9aa0a6"
              />
            </svg>
            <v-autocomplete
              class="serach-inner"
              no-data-text="..."
              type="text"
              label="Search Company"
              :items="formState.items"
              variant="solo"
              clearable
              item-title="name"
              item-value="domain"
              appendInner="mdi-magnify"
              @update:search="onSearchChannge"
              @click:clear="onSearchClear"
              @update:modelValue="onModalValue"
            >
              <template v-slot:prepend-item>
                <div class="loader-container" v-if="formState.isLoading">
                  <v-progress-circular
                    indeterminate
                    :rotate="360"
                    :size="60"
                    :width="7"
                    color="primary"
                  ></v-progress-circular>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </div>

        <div class="" v-if="formState.show">
          <div class="tbody">
            <div>
              <span>Legal Name</span>
              {{ formState.legalName }}
            </div>
            <div>
              <span>Ticker</span>
              {{ formState.ticker }}
            </div>
            <div>
              <span>Type</span>
              {{ formState.type }}
            </div>

            <div>
              <span>Location</span>
              {{ formState.location }}
            </div>
            <div>
              <span>Exchange</span>
             {{formState.exchange}}
            </div>
            <div class="description">
              <span>Description</span>
              {{ formState.description }}
            </div>
            <!-- <td>{{ formState.location.streetNumber }}, {{ formState.location.streetName }} street, {{ formState.location.streetAddress }} ,{{ formState.location.city }} ,{{ formState.location.postalCode  }} ,{{formState.location.state  }}, {{ formState.location.stateCode }}, {{ formState.location.country  }}</td> -->
          </div>
        </div>
        <div
          class="table-data w-50 mt-2 loader-container"
          v-if="formState.isLoadingDomain"
        >
          <v-progress-circular
            indeterminate
            :rotate="360"
            :size="60"
            :width="10"
            color="purple"
          ></v-progress-circular>
        </div>
      </div>
    </div>
    <footer>© 2023 IS U Public. All Rights Reserved</footer>
  </div>
</template>
<script>
import axios from "axios";
import Logo from "./icon.svg"
import { reactive, onMounted } from "vue";
export default {
  setup() {
    onMounted(() => {
      // console.log(process.env);
    });
    var token = process.env.VUE_APP_TOKEN;

    const formState = reactive({
      isLoading: false,
      query: "a",
      data: [],
      items: [],
      legalName: "",
      ticker: "",
      type: "",
      show: false,
      description: "",
      location: "",
      isLoadingDomain: false,
      alertTitle: "",
      alertMessage: "",
      alert: false,
      exchange:"Coming Soon"
    });

    const onSearchChannge = async (item) => {
      
      try {
        if (item.trim()) {
          formState.isLoading = true;
          const data = await axios.get(
            `https://autocomplete.clearbit.com/v1/companies/suggest?query=${item}&country=USA`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          formState.isLoading = false;
          formState.items = data.data;
        } else {
          formState.items = [];
          formState.isLoading = false;
        }
      } catch (error) {
        // alert(error.response);
        console.log(error);
        formState.alert = true;
        formState.alertTitle = error.response.data.error.type;
        formState.alertMessage = error.response.data.error.message;
        formState.isLoading = false;
      }
    };
    const onSearchClear = () => {
      formState.show = false;
      formState.items = [];
        formState.alert = false;

    };
    const onModalValue = (item) => {
      navigateToDomain(item);
    };
    const navigateToDomain = async (domain) => {
      if (domain) {
        formState.isLoadingDomain = true;
        try {
          const data = await axios.get(
            `https://company.clearbit.com/v2/companies/find?domain=${domain}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          var res = data.data;
          formState.legalName = res.legalName;
          formState.type = res.type;
          formState.ticker = res.ticker;
          formState.description = res.description;
          formState.location = res.location;
          formState.show = true;
          formState.isLoadingDomain = false;
        } catch (error) {
          formState.alert = true;
          console.log(error.response.data.error);
          formState.alertTitle = error.response.data.error.type;
          formState.alertMessage = error.response.data.error.message;
          // alert(error.response.data.error.message);
          formState.isLoadingDomain = false;
        }
      }
    };
    return {
      formState,
      navigateToDomain,
      onSearchChannge,
      onSearchClear,
      onModalValue,
      Logo
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.v-progress-circular {
  margin: 0.5rem;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px; /* Adjust the height based on your needs */
}
.search-div{
  width:60%;
}
footer {
  padding: 10px;
  background: #f2f2f2;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.search-holder {
  background: #fff url(https://logomaster.ai/hubfs/hero-background.png);
  background-position-x: 0%;
  background-position-y: 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-position: top 199px center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-position: top 199px center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}
.search-holder .container {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.input-field {
  position: relative;
}
.input-field svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
  left: 20px;
}

.v-input__details {
  display: none !important;
}
.v-label.v-field-label {
  margin-left: 50px !important;
}
span.v-autocomplete__selection-text {
  padding-left: 34px;
}
.v-autocomplete .v-field .v-field__input > input {
  padding-left: 50px !important;
}
.input-field .v-field.v-field--appended {
  border: none;
  border-radius: 46px;
  padding: 0;
  box-shadow: none;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16) !important;
}
.input-field .v-field.v-field--appended.v-field--active {
  border-radius: 10px;
}
body * {
  font-family: "Montserrat", sans-serif !important;
}
.tbody {
  margin: auto;
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr;
  background: #fff;
  padding: 20px;
  padding-top: 20px;
  font-size: 15px;
  gap: 10px 15px;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16) !important;
  border-radius: 10px;
  margin-top: -51px;
  padding-top: 80px;
  font-size: 14px;
  margin-left: 4px;
}
.tbody > div {
  display: flex;
  flex-direction: column;
}
.tbody > div span {
  font-weight: bold;
}
.description {
  grid-column: 1/-1;
}
.data-holder {
  margin: auto;
}
.data-holder span {
  font-weight: bold;
}
.v-field__field {
  font-size: 14px;
}
.main-title {
  font-weight: 600;
  font-size: 28px;
  text-transform: capitalize;
  background: none;
  color: #fff;
  padding: 0;
  max-width:100%; 
  line-height: normal;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
}
</style>
